<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> New shop</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label">Name <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="shopRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">Phone<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="shopRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
									<small class="text-danger">{{validationData.phone}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputAddress" class="col-sm-2 col-form-label">Address <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<textarea v-model="shopRequest.address" @blur="validationRule()"
										class="form-control" id="inputAddress"></textarea>
									<small class="text-danger">{{validationData.address}}</small>
								</div>
							</div>

							<div class="form-group row" >
								<label for="inputStatus" class="col-sm-2 col-form-label"> Region <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select id="inputStatus" class="form-control" v-model="shopRequest.location">
										<option value="">Choose Region</option>
										<option value="NPT">Nay Pyi Taw</option>
										<option value="YGN">Yangon</option>
										<option value="MDY">Mandalay</option>
									</select>
									<small class="text-danger">{{validationData.location}}</small>
								</div>
							</div>


							<div class="form-group row" style="display: none;">
								<label for="inputStatus" class="col-sm-2 col-form-label"> Status <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select id="inputStatus" class="form-control" v-model="shopRequest.status">
										<option value="unapproved">Unapproved</option>
										<option value="approved">Approved</option>
									</select>
									<small class="text-danger">{{validationData.status}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLatitude" class="col-sm-2 col-form-label">Latitude <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="shopRequest.latitude" @blur="validationRule()"
										class="form-control" id="inputLatitude">
									<small class="text-danger">{{validationData.latitude}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputLongitude" class="col-sm-2 col-form-label">Longitude <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="shopRequest.longitude" @blur="validationRule()"
										class="form-control" id="inputLongitude">
									<small class="text-danger">{{validationData.longitude}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputDescription" class="col-sm-2 col-form-label">Description <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<textarea v-model="shopRequest.description" @blur="validationRule()"
										class="form-control" id="inputDescription"></textarea>
									<small class="text-danger">{{validationData.description}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputRemark" class="col-sm-2 col-form-label">Remark <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<textarea v-model="shopRequest.remark" @blur="validationRule()"
										class="form-control" id="inputRemark"></textarea>
									<small class="text-danger">{{validationData.remark}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputOpenHour" class="col-sm-2 col-form-label">Open Hour <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="time" v-model="shopRequest.open_hour" @blur="validationRule()"
										class="form-control" id="inputOpenHour">
									<small class="text-danger">{{validationData.open_hour}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputCloseHour" class="col-sm-2 col-form-label">Close Hour <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="time" v-model="shopRequest.close_hour" @blur="validationRule()"
										class="form-control" id="inputCloseHour">
									<small class="text-danger">{{validationData.close_hour}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">Image <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="shopRequest.image" v-bind:src="shopRequest.image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">Submit</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'shopCreate',
		metaInfo: {
            title: "Shop Create",
            titleTemplate: "%s ← HoHoDiDi Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage: "",
				shopRequest: {
					name: "",
					phone: "",
					address: "",
					location: "",
					latitude: "",
					longitude: "",
					description: "",
					remark: "",
					open_hour: "",
					close_hour: "",
					status: "unapproved",
					image: ""
				},
				validationData: {
					name: "",
					phone: "",
					address: "",
					location: "",
					latitude: "",
					longitude: "",
					description: "",
					remark: "",
					open_hour: "",
					close_hour: "",
					image: "",
					status: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				shopStoreAction: 'shopStoreAction',
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.shopRequest.image = e.target.result
					this.validationRule()
				};
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.shopRequest
				await this.shopStoreAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'shop-list', query: {page: 1, reverse: 'desc', sort: 'created_at', name: '',  phone: '', address: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.shopRequest.name == "") {
					this.validationData.name = "The name field is required."
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.shopRequest.status == "") {
					this.validationData.status = "The status field is required."
					isValidation.push(true)
				} else {
					this.validationData.status = ""
					isValidation.push(false)
				}
				if (this.shopRequest.phone == "") {
					this.validationData.phone = "The phone field is required."
					isValidation.push(true)
				} else {
					this.validationData.phone = ""
					isValidation.push(false)
				}
				if (this.shopRequest.address == "") {
					this.validationData.address = "The address field is required."
					isValidation.push(true)
				} else {
					this.validationData.address = ""
					isValidation.push(false)
				}
				if (this.shopRequest.location == "") {
					this.validationData.location = "The region field is required."
					isValidation.push(true)
				} else {
					this.validationData.location = ""
					isValidation.push(false)
				}
				if (this.shopRequest.latitude == "") {
					this.validationData.latitude = "The latitude field is required."
					isValidation.push(true)
				} else {
					this.validationData.latitude = ""
					isValidation.push(false)
				}
				if (this.shopRequest.longitude == "") {
					this.validationData.longitude = "The longitude field is required."
					isValidation.push(true)
				} else {
					this.validationData.longitude = ""
					isValidation.push(false)
				}
				if (this.shopRequest.description == "") {
					this.validationData.description = "The description field is required."
					isValidation.push(true)
				} else {
					this.validationData.description = ""
					isValidation.push(false)
				}
				if (this.shopRequest.remark == "") {
					this.validationData.remark = "The remark field is required."
					isValidation.push(true)
				} else {
					this.validationData.remark = ""
					isValidation.push(false)
				}
				if (this.shopRequest.open_hour == "") {
					this.validationData.open_hour = "The open hour field is required."
					isValidation.push(true)
				} else {
					this.validationData.open_hour = ""
					isValidation.push(false)
				}
				if (this.shopRequest.close_hour == "") {
					this.validationData.close_hour = "The close hour field is required."
					isValidation.push(true)
				} else {
					this.validationData.close_hour = ""
					isValidation.push(false)
				}
				if (this.shopRequest.image == "") {
					this.validationData.image = "The image field is required."
					isValidation.push(true)
				} else {
					console.log(this.shopRequest.image)
					this.validationData.image = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>